<template>
    <div :class="isLoading ? 'tw-cursor-not-allowed' : ''">
        <div class="neo-table neo-table--expandable tw-w-full tw-text-sm tw-bg-white tw-rounded-lg tw-border-2 tw-border-solid tw-border-gray-200" style="max-height: calc(100vh - 240px)" :class="{'neo-table--empty': !pageList.length}">
            <div class="neo-table__header tw-inline-block tw-w-full" :class="isLoading ? 'tw-bg-gray-200 tw-text-gray-100' : ''">
                <tr class="neo-table__header__row tw-w-full tw-flex tw-border-0 tw-border-b-2 tw-border-solid tw-border-gray-200">
                    <th v-if="isToExpand" class="cell--dropdown">
                        <div class="tw-flex tw-justify-between tw-items-center">
                            <button v-if="pageList.length" class="tw-py-2 tw-px-2 tw-ml-2 tw-border-none tw-cursor-pointer tw-bg-transparent" :class="isLoading ? '' : 'tw-text-brand'" @click="expandAll">
                                {{ getIsAllExpanded ? "Collapse All" : "Expand All" }}
                            </button>
                        </div>
                    </th>
                    <th
                        v-for="(header, index) in headers"
                        :key="header.value + index"
                        class="tw-px-8 tw-py-4 tw-m-0 tw-sticky tw-text-left tw-self-stretch tw-block tw-break-word tw-font-bold"
                        :class="[
                            header.class,
                            header.type ? `cell--${header.type}` : '',
                            {
                                'pl-0': index == underlineFromHeader,
                            },
                        ]"
                    >
                        <span>{{ header.text }}</span>
                    </th>
                    <th v-if="editable" class="cell--controls tw-w-1/6 tw-m-0 tw-px-3 tw-py-2 tw-text-left tw-self-center tw-block tw-break-word">
                        <button class="tw-text-white tw-py-2 tw-px-3 tw-rounded-md tw-border-none tw-cursor-pointer" :class="isLoading ? '' : 'tw-bg-brand hover:tw-bg-blue-500'" @click="$emit('update:row')">
                            Add new
                        </button>
                    </th>
                </tr>
            </div>

            <component :is="tableBodySettings.component" class="neo-table__body tw-overflow-y-auto tw-overflow-x-hidden tw-inline-block tw-w-full scroll-bar" :style="`${isLoading && !pageList.length ? 'height' : 'max-height'}: calc(100vh - 310px)`" v-bind="tableBodySettings.props" @end="handleDragEnd">
                <tr v-if="isLoading && !pageList.length" class="tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center tw-mx-auto">
                    <td colspan="4" rowspan="5" class="tw-self-center">
                        <neo-loader class="tw-mr-2.5" :loading="isLoading" height="40px" width="40px" margin="auto"></neo-loader>
                    </td>
                </tr>

                <template v-else>
                    <div v-if="isLoading" class="loader">
                        <neo-loader :loading="isLoading" height="40px" width="40px" margin="auto"></neo-loader>
                    </div>
                    <div v-if="noMatchFound" class="no-match">
                        No match found
                    </div>

                    <!-- // TECH-3749 //-->
                    <div class="tw-bg-indigo-50 tw-mx-8 tw-my-8 tw-p-6 tw-rounded-md tw-text-center" v-if="pageList.length === 0">
                        No Records Found!
                    </div>

                    <div v-for="(item, itemIndex) in pageList" :key="item[headers[searchFilterIndex].value] + itemIndex" class="tw-border-0 tw-border-solid tw-border-gray-200 neo-table__body__row-wrapper" :class="{'neo-table__body__row-wrapper--expanded': expanded[item.id]}" :data-id="item.id || itemIndex">
                        <tr
                            class="neo-table__body__row row--main tw-w-full tw-flex tw-relative"
                            :class="{
                                'tw-font-bold': isToExpand,
                                'tw-cursor-pointer hover:tw-bg-blue-50': !isLoading,
                                loading: isLoading,
                            }"
                            @click="expand(item.id)"
                        >
                            <td v-if="isToExpand" class="cell--dropdown">
                                <div class="dropdown-icon" :class="{'dropdown-icon--expanded': expanded[item.id]}">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="tw-w-7 tw-transition-transform tw-duration-300 tw-transform tw-text-brand" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                            </td>
                            <td
                                v-for="(itemProp, index) in headers"
                                class="cell tw-m-0 tw-px-8 tw-py-4 tw-text-left tw-self-stretch tw-block tw-break-word tw-border-0"
                                :class="[
                                    itemProp.class,
                                    itemProp.type ? `cell--${itemProp.type}` : '',
                                    {
                                        'tw-border-solid tw-border-gray-200': index >= underlineFromHeader,
                                        'pl-0': index == underlineFromHeader,
                                    },
                                ]"
                                :key="itemProp.value + index"
                            >
                                <div v-if="itemProp.type === 'toggle'">
                                    <neo-toggle-switch :status="item.checked" :disabled="isLoading" class="tw-cursor-pointer" :key="item.checked.toString() + index" @click="check(item, !item.checked, 'general')" />
                                </div>
                                <div v-else-if="itemProp.type === 'file'">
                                    <font-awesome-icon title="Download" class="tw-text-brand" icon="download" style="width: 16px; height: 16px" @click="$emit('download', item.file)"></font-awesome-icon>
                                </div>
                                <div v-else class="tw-flex tw-items-center tw-w-full">
                                    <span>
                                        {{ itemProp.maxLength && item[itemProp.value].length > itemProp.maxLength && !expandedCell[index][itemIndex] ? limitString(item[itemProp.value], itemProp.maxLength) : item[itemProp.value] }}
                                        <div v-if="itemProp.maxLength && item[itemProp.value].length > itemProp.maxLength" class="show-more" @click="expandCell(index, itemIndex)">
                                            {{ expandedCell[index][itemIndex] ? "show less" : "show more" }}
                                        </div>
                                    </span>
                                    <div v-if="expandable && index === 0" class="count">
                                        <div v-if="item[sublistProp].length" :class="['count__number', `length--${item[sublistProp].length.toString().length}`]">
                                            {{ item[sublistProp].length }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td v-if="isEditable.general" class="cell--controls tw-w-1/6 tw-m-0 tw-px-3 tw-py-4 tw-text-left tw-self-stretch tw-block tw-border-0 tw-break-word tw-border-solid tw-border-gray-200">
                                <div class="wrapper tw-flex tw-items-center tw-flex-grow tw-justify-around">
                                    <font-awesome-icon
                                        title="Edit"
                                        class="tw-text-brand"
                                        icon="edit"
                                        style="width: 16px; height: 16px"
                                        @click.stop="
                                            $emit('update:row', {
                                                item,
                                                type: 'general',
                                            })
                                        "
                                    />
                                    <font-awesome-icon
                                        title="Delete"
                                        class="tw-text-brand"
                                        icon="trash"
                                        style="width: 16px; height: 16px"
                                        @click.stop="
                                            $emit('remove:row', {
                                                item,
                                                type: 'general',
                                            })
                                        "
                                    />
                                </div>
                            </td>
                        </tr>
                        <table-sublist
                            v-if="isToExpand && expanded[item.id] && item[sublistProp].length"
                            class="sublist-wrapper"
                            :editable="isEditable.sub"
                            :list="item[sublistProp]"
                            :headers="headers"
                            :sublistHeaders="sublistHeaders"
                            :searchFilterIndex="sublistHeaders[searchFilterIndex].value"
                            :underlineFromHeader="underlineFromHeader"
                            :isLoading="isLoading"
                            :draggable="isDraggable.sub"
                            @update:row="
                                $emit('update:row', {
                                    ...$event,
                                    parent: item,
                                    type: 'sub',
                                })
                            "
                            @remove:row="
                                $emit('remove:row', {
                                    ...$event,
                                    parent: item,
                                    type: 'sub',
                                })
                            "
                            @checked="
                                $emit('checked', {
                                    ...$event,
                                    parent: item,
                                    type: 'sub',
                                })
                            "
                            @update:list="
                                $emit('update:list', {
                                    ...$event,
                                    parent: item,
                                    type: 'sub',
                                })
                            "
                        />
                    </div>
                </template>
            </component>
        </div>
        <neo-pagination v-if="getTotalPageCount > 1" class="tw-mt-2" v-model="currentPage" :totalPageCount="getTotalPageCount" />
    </div>
</template>

<script>
import Loader from "@/components/loader";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import NeoToggleSwitch from "@/components/toggle-switch";

const Sublist = () => import("@/components/table-expandable/components/sublist.vue");
const Pagination = () => import("@/components/pagination");
const Draggable = () => import("vuedraggable");

export default {
    name: "neo-table-expandable",
    components: {
        "neo-loader": Loader,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-pagination": Pagination,
        "neo-toggle-switch": NeoToggleSwitch,
        "table-sublist": Sublist,
    },
    props: {
        underlineFromHeader: {
            type: Number,
            default: () => 0,
        },
        list: {
            type: Array,
            default: () => [],
        },
        headers: {
            type: Array,
            default: () => [],
        },
        sublistProp: {
            type: String,
            default: () => "list",
        },
        sublistHeaders: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: () => false,
        },
        // list must be updated on every change
        draggable: {
            type: [Boolean, Object],
            default: () => false,
        },
        expandable: {
            type: Boolean,
            default: () => false,
        },
        editable: {
            type: [Boolean, Object],
            default: () => false,
        },
        pagination: {
            type: Boolean,
            default: () => true,
        },
        // use if pagination on the backend side
        asyncPages: {
            type: Boolean,
            default: () => false,
        },
        // use if pagination on the backend side
        pageCount: {
            type: Number,
            default: () => 0,
        },
        searchFilter: {
            type: String,
            default: () => "",
        },
        searchFilterKey: {
            // TODO: remove from tables, use header prop 'search'
            type: String,
            default: () => "name",
        },
        pageSize: {
            type: Number,
            default: () => 10,
        },
    },
    data() {
        return {
            expanded: {},
            expandedCell: {},
            isAllExpanded: false,
            currentPage: 1,
        };
    },
    created() {
        this.resetExpandCell();
    },
    computed: {
        isEditable() {
            if (typeof this.editable === "object") return this.editable;
            else return {general: !!this.editable};
        },
        isDraggable() {
            if (typeof this.draggable === "object") return this.draggable;
            else return {general: !!this.draggable};
        },
        isToExpand() {
            // return this.expandable && this.pageList.find((el) => el[this.sublistProp].length);
            return this.expandable; // if we need to hide expand-buttons if there is nothing to expand, uncomment previous line
        },
        tableBodySettings() {
            return {
                component: this.isDraggable.general ? Draggable : "div",
                props: this.isDraggable.general
                    ? {
                          tag: "div",
                          list: this.pageList,
                      }
                    : {},
            };
        },
        searchFilterIndex() {
            return this.headers.findIndex((el) => el.search || el.value === this.searchFilterKey) || 0;
        },
        searchFilterSubIndex() {
            return this.sublistHeaders.findIndex((el) => el.search || el.value === this.searchFilterKey) || 0;
        },
        filteredList() {
            return this.list.filter((category) => {
                return this.match(category[this.headers[this.searchFilterIndex].value]) || category[this.sublistProp]?.find((query) => this.match(query[this.sublistHeaders[this.searchFilterSubIndex].value]));
            });
        },
        computedPageSize() {
            return this.pagination ? this.pageSize : this.list.length;
        },
        getTotalPageCount() {
            if (this.asyncPages) return this.pageCount;
            return Math.ceil(this.filteredList.length / this.computedPageSize);
        },
        pageList() {
            if (!this.pagination || this.asyncPages) return this.filteredList;
            const elFrom = (this.currentPage - 1) * this.computedPageSize,
                elTo = elFrom + this.computedPageSize;
            return this.filteredList.slice(elFrom, elTo);
        },
        noMatchFound() {
            return !this.pageList.length && this.searchFilter;
        },
        getIsAllExpanded() {
            let isAllExpanded = this.isAllExpanded;
            if (this.pageList.length === Object.values(this.expanded).length) {
                isAllExpanded = Object.values(this.expanded).every((exp) => exp);
            }
            return isAllExpanded;
        },
    },
    watch: {
        currentPage(newValue) {
            this.resetExpand();
            this.resetExpandCell();
            this.$emit("update:page", newValue);
        },
        pageList(newValue) {
            // * Change page if current page become empty after row removing
            if (!newValue.length && this.currentPage > 1) this.currentPage--;
        },
        // expand automatically if searched worn inside child element
        searchFilter(newValue) {
            this.currentPage = 1;
            this.resetExpandCell();
            if (this.isToExpand) {
                this.resetExpand(); // ? should we collapse all rows on every search?
                if (newValue) {
                    this.pageList.forEach((category) => {
                        const toExpand = category[this.sublistProp].find((item) => this.match(item[this.sublistHeaders[this.searchFilterSubIndex].value]));
                        if (toExpand) this.expand(category.id, true);
                    });
                }
                if (Object.values(this.expanded).filter((el) => el).length === this.pageList.length) this.isAllExpanded = true;
            }
        },
    },
    methods: {
        handleDragEnd(e) {
            if (e.newDraggableIndex == e.oldDraggableIndex) return;
            const down = e.newDraggableIndex > e.oldDraggableIndex;
            let oldIndex = e.oldDraggableIndex;
            const newIndex = e.newDraggableIndex;
            // should be optimized, need draggable lib investigation
            if (!down && oldIndex - newIndex !== 1) oldIndex++;
            const currEl = this.pageList[oldIndex];
            const prevEl = newIndex ? this.pageList[newIndex - 1] : null;
            let oldPosition = this.list.findIndex((el) => el.id === currEl.id);
            const updatedArr = [...this.list];
            updatedArr.splice(oldPosition, 1);
            let newPosition = prevEl ? updatedArr.findIndex((el) => el.id === prevEl.id) + 1 : 0;
            if (prevEl && prevEl.id === currEl.id) {
                // el was moved right by 1 position
                newPosition = oldPosition + 1;
            }
            updatedArr.splice(newPosition, 0, currEl);
            this.$emit("update:list", {
                list: updatedArr.map((el, index) => ({...el, order: index})),
                row: {
                    item: currEl,
                    position: newPosition,
                },
                type: "general",
            });
        },
        match(text) {
            return text.toLowerCase().includes(this.searchFilter.toLowerCase());
        },
        expand(index, value = !this.expanded[index]) {
            if (!this.isToExpand) return;
            // if we collapse row change isAllExpanded to false
            if (this.expanded[index]) this.isAllExpanded = false;
            this.$set(this.expanded, index, value);
        },
        check(item, value, type) {
            this.$emit("checked", {item, value, type});
        },
        expandAll() {
            // if isAllExpanded is true method will collapse all
            if (this.isAllExpanded) {
                this.resetExpand();
            } else {
                this.pageList.forEach((el) => {
                    this.expand(el.id, true);
                });
                this.isAllExpanded = true;
            }
        },
        resetExpand() {
            this.expanded = {};
            this.isAllExpanded = false;
        },
        expandCell(headerIndex, rowIndex) {
            this.$set(this.expandedCell[headerIndex], rowIndex, !this.expandedCell[headerIndex][rowIndex]);
        },
        resetExpandCell() {
            this.headers.forEach((el, index) => {
                this.$set(this.expandedCell, index, {});
            });
        },
        limitString(value, limit) {
            return value.substring(0, limit) + "...";
        },
    },
};
</script>

<style src="./styles.scss" scoped lang="scss"></style>
